<template>
  <div
    class="game-params-root"
  >
    <div
      class="game-params-content"
    >
      <div
        v-if="gameParams && activeTab === 'game-params'"
        class="global-params-header"
      >
        <div class="global-param-header-single">
          <div class="global-param-header-title">
            Start RHO (Air density)
          </div>
          <div class="global-param-header-content">
            <GameParamNumberInput
              :param="rhoStart"
              @paramChange="onGlobalParamChange"
              @paramIncrease="onGlobalParamIncrease"
              @paramDecrease="onGlobalParamDecrease"
            />
            <GameParamNumberInput
              :param="rhoStartManual"
              :disabled="autoMode"
              @paramChange="onGlobalParamChange"
              @paramIncrease="onGlobalParamIncrease"
              @paramDecrease="onGlobalParamDecrease"
            />
          </div>
        </div>
        <div class="global-param-header-single">
          <div class="global-param-header-title">
            End RHO (Air density)
          </div>
          <div class="global-param-header-content">
            <GameParamNumberInput
              :param="rhoEnd"
              @paramChange="onGlobalParamChange"
              @paramIncrease="onGlobalParamIncrease"
              @paramDecrease="onGlobalParamDecrease"
            />
            <GameParamNumberInput
              :param="rhoEndManual"
              :disabled="autoMode"
              @paramChange="onGlobalParamChange"
              @paramIncrease="onGlobalParamIncrease"
              @paramDecrease="onGlobalParamDecrease"
            />
          </div>
        </div>
        <div class="divider" />
        <div class="global-param-header-single">
          <div class="global-param-header-title">
            Home run rate
          </div>
          <div class="global-param-header-content">
            <GameParamNumberInput
              :param="homeRunRate"
              :disabled="autoMode"
              @paramChange="onGlobalParamChange"
              @paramIncrease="onGlobalParamIncrease"
              @paramDecrease="onGlobalParamDecrease"
            />
            <div class="global-param-header-toggle">
              <Toggle
                :model-value="useHomeRunRate.value"
                :disabled="autoMode"
                @update:model-value="value => { onGlobalParamChange(value, useHomeRunRate) }"
              />
              <span>{{ useHomeRunRate.label }}</span>
            </div>
          </div>
        </div>
        <div class="divider" />
        <div class="global-param-header-single">
          <div class="global-param-header-content">
            <div class="park-id-dropdown-root">
              <span class="park-id-label">ParkId</span>
              <Dropdown
                class="park-id-dropdown"
                :label="parkId?.toString() || 'Select ParkId'"
                :disabled="autoMode"
              >
                <DropdownItem
                  v-for="park in parkIdValues"
                  :key="park.parkId"
                  @click="selectParkId(park)"
                  clickable
                >
                  {{ park.parkId + ' - ' + park.parkName }}
                </DropdownItem>
              </Dropdown>
            </div>
          </div>
        </div>
        <Button
          :icon="showAllGameParams ? 'chevron-up' : 'chevron-down'"
          @click="toggleShowAllGameParams"
        >
          {{ showAllGameParams ? 'Show less' : 'Show more' }}
        </Button>
      </div>
      <div
        v-if="showAllGameParams && activeTab === 'game-params'"
        class="additional-global-params"
      >
        <div class="projections-items-root">
          <div
            v-for="(projection, index) in projectionsSetup"
            :key="index"
            class="projections-item-single"
          >
            <div class="projection-item-title">
              {{ projection.title }}
            </div>
            <div class="projection-item-content">
              <GameParamNumberInput
                v-for="(param) in projection.params"
                :key="param.shortName"
                :param="param"
                :disabled="autoMode"
                @paramChange="onGlobalParamChange"
                @paramIncrease="onGlobalParamIncrease"
                @paramDecrease="onGlobalParamDecrease"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        class="team-params-root-baseball"
      >
        <BaseballTeamParams
          :team="teamA"
          :auto-mode="autoMode"
          @paramChange="onGlobalParamChange"
          @paramIncrease="onGlobalParamIncrease"
          @paramDecrease="onGlobalParamDecrease"
          @onPitcherHitterTabChange="onPitcherHitterTeamATabChange"
        />
        <div class="divider" />
        <BaseballTeamParams
          :team="teamB"
          :auto-mode="autoMode"
          @paramChange="onGlobalParamChange"
          @paramIncrease="onGlobalParamIncrease"
          @paramDecrease="onGlobalParamDecrease"
          @onPitcherHitterTabChange="onPitcherHitterTeamBTabChange"
        />
      </div>
      <div
        v-if="activeTab === 'player-params'"
        class="player-markets-per-team-root"
      >
        <MarketsPerPlayer
          :team-data="teamA"
          :competition-type="eventData?.competitionType"
          :player-type-active-tab="teamAPitcherHitterActiveTab"
        />
        <div class="divider" />
        <MarketsPerPlayer
          :team-data="teamB"
          :competition-type="eventData?.competitionType"
          :player-type-active-tab="teamBPitcherHitterActiveTab"
        />
      </div>
      <ParamsSetupMarkets
        v-if="activeTab === 'game-params'"
        :auto-mode="autoMode"
        :event-id="eventId"
        :competition-type="eventData?.competitionType"
      />
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { cloneDeep, filter } from 'lodash';
import GameParamNumberInput from '@/components/player-setup/common/GameParamNumberInput';
import BaseballTeamParams from '@/components/player-setup/baseball/BaseballTeamParams';
import MarketsPerPlayer from '@/components/player-setup/setup-by-team/MarketsPerPlayer';
import Toggle from '@/components/common/Toggle';
import Button from '@/components/common/Button';
import ParamsSetupMarkets from '../ParamsSetupMarkets';
import Dropdown from '@/components/common/Dropdown';
import DropdownItem from '@/components/common/DropdownItem';
import { createBaseballGlobalParams } from '../params-helper';

export default {
  emits: ['toggleShowAllTeamParameters'],
  components: {
    GameParamNumberInput,
    BaseballTeamParams,
    Toggle,
    Button,
    ParamsSetupMarkets,
    MarketsPerPlayer,
    Dropdown,
    DropdownItem,
  },
  props: {
    sportId: {
      type: String,
      default: '',
    },
    activeTab: {
      type: String,
      default: () => '',
    },
    autoMode: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const store = useStore();
    const gameParamsConfig = computed(() => createBaseballGlobalParams());
    const gameParams = computed(() => store.getters.playerSetupGameParams);
    const showAllGameParams = ref(false);
    // Game header params
    // RHO Start
    const rhoStart = computed(() => gameParams.value?.weatherData?.rhoStart);
    const rhoStartManual = computed(() => gameParams.value?.weatherData?.rhoStartManual);

    // RHO end
    const rhoEnd = computed(() => gameParams.value?.weatherData?.rhoEnd);
    const rhoEndManual = computed(() => gameParams.value?.weatherData?.rhoEndManual);
    // Home run rate
    const homeRunRate = computed(() => gameParams.value?.homeRunRate);
    const useHomeRunRate = computed(() => gameParams.value?.useHomeRunRate);

    // Park ID
    const parkId = computed(() => gameParams.value?.parkId.value);
    const parkIdValues = computed(() => filter(store.getters.allBaseballParks, (park) => parkId.value !== park.parkId));

    // Additional params (params on show more)
    const projections = computed(() => gameParams.value?.projections);
    const mapProjectionsParams = (param) => Object.keys(param).map((key) => param[key]);
    const projectionsSetup = computed(() => ([
      {
        title: gameParamsConfig.value?.projections?.league?.title?.text,
        params: mapProjectionsParams(projections.value?.league),
      },
      {
        title: gameParamsConfig.value?.projections?.park?.title?.text,
        params: mapProjectionsParams(projections.value?.park),
      },
      {
        title: gameParamsConfig.value?.projections?.homeTeam?.title?.text,
        params: mapProjectionsParams(projections.value?.homeTeam),
      },
      {
        title: gameParamsConfig.value?.projections?.awayTeam?.title?.text,
        params: mapProjectionsParams(projections.value?.awayTeam),
      },
      {
        title: gameParamsConfig.value?.projections?.leftieToLeftie?.title?.text,
        params: mapProjectionsParams(projections.value?.leftieToLeftie),
      },
      {
        title: gameParamsConfig.value?.projections?.leftieToRightie?.title?.text,
        params: mapProjectionsParams(projections.value?.leftieToRightie),
      },
      {
        title: gameParamsConfig.value?.projections?.rightieToLeftie?.title?.text,
        params: mapProjectionsParams(projections.value?.rightieToLeftie),
      },
      {
        title: gameParamsConfig.value?.projections?.rightieToRightie?.title?.text,
        params: mapProjectionsParams(projections.value?.rightieToRightie),
      },
    ]));

    // Team data
    const eventData = computed(() => store.getters.playerSetupData || {});
    const eventId = computed(() => eventData.value?.eventId);
    const teamA = computed(() => ({
      teamId: eventData.value.teamA?.teamId,
      name: eventData.value.teamA?.name,
      side: eventData.value.teamA?.label,
      label: 'teamA',
      teamLabel: 'teamA',
      sportId: eventData.value?.sportId,
      lineup: eventData.value.teamA?.lineup || [],
    }));
    const teamAPitcherHitterActiveTab = ref('pitchers');
    const onPitcherHitterTeamATabChange = (tab) => {
      teamAPitcherHitterActiveTab.value = tab;
    };
    const teamB = computed(() => ({
      teamId: eventData.value.teamB?.teamId,
      name: eventData.value.teamB?.name,
      side: eventData.value.teamB?.label,
      label: 'teamB',
      teamLabel: 'teamB',
      sportId: eventData.value?.sportId,
      lineup: eventData.value.teamB?.lineup || [],
    }));
    const teamBPitcherHitterActiveTab = ref('pitchers');
    const onPitcherHitterTeamBTabChange = (tab) => {
      teamBPitcherHitterActiveTab.value = tab;
    };

    // Handlers
    const onGlobalParamChange = (value, param) => {
      const updatedParam = cloneDeep(param);

      if (param.type === 'boolean' || param.type === 'select') {
        updatedParam.value = value;
        store.dispatch('updatePlayerSetupBaseballGameParams', updatedParam);
        return;
      }
      if (value) {
        const paramValue = parseFloat(value);
        const isError = paramValue < param?.minValue || paramValue > param?.maxValue;
        updatedParam.value = paramValue;
        updatedParam.isError = isError;
      } else {
        updatedParam.isError = true;
        updatedParam.value = null;
      }
      store.dispatch('updatePlayerSetupBaseballGameParams', updatedParam);
    };

    const onGlobalParamIncrease = (param) => {
      const updatedParam = cloneDeep(param);
      const updatedValue = parseFloat((updatedParam.value + updatedParam.raiseIncrement).toFixed(3));
      if (updatedValue <= updatedParam.maxValue) {
        updatedParam.value = updatedValue;
        store.dispatch('updatePlayerSetupBaseballGameParams', updatedParam);
      }
    };

    const onGlobalParamDecrease = (param) => {
      const updatedParam = cloneDeep(param);
      const updatedValue = parseFloat((updatedParam.value - updatedParam.raiseIncrement).toFixed(3));
      if (updatedValue >= updatedParam.minValue) {
        updatedParam.value = updatedValue;
        store.dispatch('updatePlayerSetupBaseballGameParams', updatedParam);
      }
    };

    const toggleShowAllGameParams = () => {
      showAllGameParams.value = !showAllGameParams.value;
    };

    const selectParkId = (park) => {
      onGlobalParamChange(park.parkId, gameParams.value.parkId);
    };

    return {
      gameParams,
      rhoStart,
      rhoStartManual,
      rhoEnd,
      rhoEndManual,
      homeRunRate,
      useHomeRunRate,
      parkId,
      onGlobalParamChange,
      onGlobalParamIncrease,
      onGlobalParamDecrease,
      toggleShowAllGameParams,
      showAllGameParams,
      teamA,
      teamB,
      eventId,
      projectionsSetup,
      eventData,
      parkIdValues,
      selectParkId,
      onPitcherHitterTeamATabChange,
      teamAPitcherHitterActiveTab,
      onPitcherHitterTeamBTabChange,
      teamBPitcherHitterActiveTab,
    };
  },
};
</script>

<style lang="scss">
.game-params-root {
  height: 100%;
  padding: 0;
  .game-params-content {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    .global-params-header {
      height: 100px;
      width: 100%;
      display: flex;
      align-items: center;
      gap: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid $gray400;
      padding: 16px;
      .global-param-header-single {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        gap: 8px;
        .global-param-header-title {
          font-size: 12px;
          font-weight: 600;
          line-height: 14px;
        }
        .global-param-header-content {
          display: flex;
          align-items: flex-end;
          gap: 8px;
          .park-id-dropdown-root {
            .park-id-label {
              display: inline-block;
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              color: #6D6D6D;
              text-transform: uppercase;
              font-size: 10px;
              line-height: 12px;
              font-weight: 600;
            }
            .park-id-dropdown {
              display: flex;
              flex-direction: column;
              .dropdown-menu {
                max-height: 300px;
                overflow: auto;
                .dropdown-item {
                  padding: 10px 12px;
                }
              }
            }
          }
          .global-param-header-toggle {
            display: flex;
            align-items: center;
            gap: 4px;
            margin-left: 16px;
          }
        }
      }
      .divider {
        width: 1px;
        height: 100%;
        background-color: $gray400;
      }
      .button {
        margin-left: auto;
        margin-top: auto;
        .icon svg path {
          stroke: $black;
        }
      }
    }
    .additional-global-params {
      background-color: $gray300;
      padding: 16px;
      .projections-items-root {
        display: flex;
        flex-wrap: wrap;
        gap: 24px;
        justify-content: space-between;
        .projections-item-single {
          display: flex;
          flex-direction: column;
          gap: 8px;
          .projection-item-title {
            font-size: 10px;
            font-weight: 600;
            line-height: 12px;
            color: $primary500;
            text-transform: uppercase;
          }
          .projection-item-content {
            display: flex;
            flex-wrap: wrap;
            gap: 16px;
          }
        }
      }
    }
    .divider {
      width: 1px;
      height: 100%;
      background-color: $gray400;
    }
    .team-params-root-baseball {
      display: flex;
      gap: 16px;
      height: 50%;
      padding-bottom: 0;
    }
    .player-markets-per-team-root {
      display: flex;
      gap: 16px;
      width: 100%;
      height: 50%;
      padding: 0 16px;
      .markets-per-player {
        width: 50%;
        height: 100%;
        .section-header {
          padding-top: 16px;
        }
      }
    }
  }
}
</style>
